// Fonts
$font-family-base: 'Roboto', sans-serif;

// Colors
$light-blue: #c0e5f5;
$light-green: #d8df26;
$dark-green: #00a59b;
$green: #16b252;
$green-alt: #00b175;
$primary: #07294d;
$secondary: #ecb700;


$theme-colors: (
);

$body-bg: #edf0f2;

// Navbar
$navbar-padding-y: 1.5rem;
$navbar-light-color: #07294d;
$navbar-light-hover-color: #ffc600;
$navbar-light-active-color: #ffc600;
$navbar-light-active-background-color: transparent;
$navbar-toggler-border-radius: 0;
$navbar-light-toggler-border-color: transparent;
$dropdown-border-radius: 7px;
$dropdown-link-hover-bg: $primary;
$dropdown-link-hover-color: white;

// Grid
$grid-columns: 24;

// Inputs & Buttons
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-padding-x-lg: 2rem;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-width: 2px;
$btn-font-size-lg: 1.25rem;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  '2-3': ($spacer * .75),
  3: $spacer,
  '3-4': ($spacer * 1.25),
  4: ($spacer * 1.5),
  '4-5': ($spacer * 2),
  5: ($spacer * 3)
);

// Other
$link-hover-decoration: none;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$bubble-opacity: 0.9;

$custom-file-text: (
  en: "Browse",
  ro: "Incarca"
);
